<template>
  <div class="input-group">
    <input
      type="text"
      readonly
      class="form-control text-right"
      :placeholder="$t('search-tab.choose-date')"
      @click="toggleCalendar"
      style="cursor: auto"
      :value="inputText"
      ref="inputControl"
      dir="ltr"
    />
    <div class="input-group-append">
      <span class="input-group-text">
        <i class="far fa-calendar-alt"></i>
      </span>
    </div>
    <div class="col-12 calendar-body" v-if="show">
      <div class="card mb-2 calendar-card">
        <div class="card-text p-2">
          <div class="row">
            <div v-for="(monthYear, i) of monthYears" :key="i" class="month-container" :style="{ width: 100 / noOfMonthsInRow + '%' }">
              <table>
                <thead>
                  <tr>
                    <th class="text-center month-title" :colspan="weekDays.length">
                      <span v-if="i == 0" class="float-left" style="cursor: pointer; padding-left: 8px" @click="nextMonth">
                        <i class="arrow left"></i>
                      </span>
                      {{ displayMonthYear(monthYear) }}
                      <span v-if=" (noOfMonthsInRow == 1 && i == 0) || (noOfMonthsInRow == 2 && i == 1)"
                      class="float-right" style="cursor: pointer; padding-right: 8px" @click="previousMonth">
                        <i class="arrow right"></i>
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th v-for="(weekDay, i) of weekDays" :key="i" class="day-header text-center">
                      {{ weekDay }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(daysOfWeek, i) of weekWiseDays(monthYear)" :key="i">
                    <td
                      v-for="(day, j) of daysOfWeek"
                      :key="j"
                      class="text-center"
                      :title="isHoliday(day)"
                      :class="{
                        available: isAvailable(day)
                          ? true
                          : false,
                        'start-date': isStartDate(day),
                        'end-date': isEndDate(day),
                        'under-selection': isUnderSelection(
                          day
                        ),
                        holiday: !!isHoliday(day),
                        today: isToday(day)
                      }"
                      @click="
                        isAvailable(day)
                          ? selectDate(day)
                          : null
                      "
                      @mouseenter="
                        isAvailable(day)
                          ? enterDate(day)
                          : null
                      "
                    >
                      <div v-if="day">
                        {{ day.format("D") }}
                        <!-- <div class="price-text">
                          {{ dayPrice(day) }}
                        </div> -->
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="d-flex flex-row">
            <ul
              class="reference-list d-flex p-0 align-self-center m-0"
              style="color: black;"
              v-if="showNotify"
            >
              <li class="d-flex">
                <div class="reference holiday m-1"></div>
                {{ $t("calendar.holiday") }}
              </li>
            </ul>
            <div
              class="flex-fill m-1"
              :class="{ 'm-1': type !== 'side' }"
            >
              <button
                class="btn btn-secondary btn-sm btn-block"
                @click="clearDatesSelection"
                :disabled="
                  selectionDate1 == null ? true : false
                "
              >
                {{ $t("calendar.clear") }}
              </button>
            </div>
            <div class="flex-fill m-1">
              <button class="btn btn-primary btn-sm btn-block" @click="finalizeSelection" :disabled="selectionDate1 && selectionDate2 ? false : true">
                {{ $t("calendar.continue") }}
              </button>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import TemplateCustomDatepicker from './TemplateCustomDatepickerRange';

require('dayjs/locale/he');
require('dayjs/locale/ru');
require('dayjs/locale/ar');

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export default {
  name: 'CustomDatepickerRange',
  mixins: [TemplateCustomDatepicker],
};
</script>

<style scoped lang="scss">
  .input-group{
    .input-group-append{
      .input-group-text {
        border-top-left-radius: 50px !important;
        border-bottom-left-radius: 50px !important;
      }
    }
    > .form-control:not(:last-child){
      border-top-right-radius: 50px !important;
      border-bottom-right-radius: 50px !important;
    }
  }

  .calendar-body {
    position: absolute;
    top: 45px;
    z-index: 10;
    padding: 0px;
    .month-title {
      color: black;
    }
  }
  .calendar-card {
    min-width: 250px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  }
  .month-container {
    padding: 10px;

    table {
      width: 100%;
      margin-bottom: 10px;

      th,
      td > div {
        padding: 5px;
      }

      td {
        padding: 0;
        position: relative;
        background-color: #fff;
        color: #c3ceda;
        pointer-events: none;
        cursor: default;
        .price-text {
          font-size: 9px;
        }
      }

      td.under-selection{
        div {
          background-color: #0db04b !important;
          color: #fff !important;
        }
      }
      td.available{
        background-color: #e0efff;
        div{
          pointer-events: all;
          background-color: #e0efff;
          color: #000;
          cursor: pointer;
          user-select: none; /* supported by Chrome and Opera */
          -webkit-user-select: none; /* Safari */
          -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
        }
      }
      td.start-date{
        div{
          border-bottom-right-radius: 20px;
          border-top-right-radius: 20px;
          background-color: #0db04b !important;
        }
      }
      td.end-date {
        div {
          border-bottom-left-radius: 20px;
          border-top-left-radius: 20px;
          background-color: #0db04b !important;
        }
      }
      .today div {
        background-color: #ebf0f1 !important;
      }
      td.holiday:after {
        content: "";
        height: 18px;
        left: 0;
        position: absolute;
        top: 0;
        width: 18px;
      }
      .day-header {
        color: #0db04b;
      }
    }
  }

.reference.holiday,
.month-container td.holiday:after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDY5LjU1IDY5LjU1Ij48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6I2ZmZn08L3N0eWxlPjwvZGVmcz48cGF0aCBkPSJNNjkuNTUgMCAwIDY5LjU1VjBoNjkuNTVabTAgMCIgc3R5bGU9ImZpbGw6IzAxNzJkZiIvPjxwYXRoIGNsYXNzPSJjbHMtMiIgZD0iTTkuNzQgMjUuNDFhNi4xOCA2LjE4IDAgMCAxIDEuNDgtMS4xNSAyLjczIDIuNzMgMCAwIDEgMS4zNC0uMzMgMi42NSAyLjY1IDAgMCAxIDEuMjkuNCA2LjgyIDYuODIgMCAwIDEgMS4zMSAxLjA2bDYuMTEgNi4xMWExMi43NyAxMi43NyAwIDAgMCAyLjQxIDEuOSAxNC44IDE0LjggMCAwIDAgMi40NCAxLjIxTDIzLjcyIDM3YTUgNSAwIDAgMS0xLjM3LS41OSAxMi4yOSAxMi4yOSAwIDAgMS0xLjI3LS44NmMtLjQ0LS4zNS0uODctLjcyLTEuMy0xLjExYTcuNDYgNy40NiAwIDAgMSAuODEgMS42IDUuMTkgNS4xOSAwIDAgMSAuMzIgMS45NiA0LjI3IDQuMjcgMCAwIDEtLjQ0IDEuODkgNi41MiA2LjUyIDAgMCAxLTEuMjYgMS43bC0xLjA3IDEuMDEtMi4yMS0yLjIxIDEuMzEtMS4zYTQuNCA0LjQgMCAwIDAgMS0xLjg3IDQuNzUgNC43NSAwIDAgMCAuMS0xLjkxIDUuNDYgNS40NiAwIDAgMC0uNjQtMS44MSA3Ljg3IDcuODcgMCAwIDAtMS4yOS0xLjY4bC0zLjc2LTMuNzZhMSAxIDAgMCAwLS41OS0uMzUuOS45IDAgMCAwLS42NC4zNWwtMi4xNCAyLjE4LTIuMTgtMi4xOVpNMzAuNTIgOWwtMS4xNyAxLjE3TDQwIDIwLjc5bC0yLjYzIDIuNjEtMTAuNjUtMTAuNjUtNC44NCA0Ljg0IDEwLjY0IDEwLjY1LTIuNjIgMi42Mi0xMC42Mi0xMC42Mi0xLjIxIDEuMjEtMi4xOS0yLjE5TDI4LjM0IDYuODFaIi8+PC9zdmc+);
  background-position: 0 0;
  background-repeat: no-repeat;
}

.reference.available {
  pointer-events: all;
  background-color: #e0efff;
  color: #000;
  cursor: pointer;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.month-title {
  font-weight: bolder;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.arrow.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.arrow.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.reference-list li {
  list-style: none;
}

.reference {
  width: 18px;
  height: 18px;
}

.loading-message{
  font-size: 1.3rem;
}
</style>
