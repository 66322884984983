import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

require('dayjs/locale/he');
require('dayjs/locale/ru');
require('dayjs/locale/ar');

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export default {
  props: {
    // availableDates: {
    //   type: Array,
    //   required: true,
    // },
    calendarShow: {
      type: Boolean,
      default: false,
    },
    destination: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'tabs',
    },
    showNotify: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tempSelectionDate2: null,
      weekDays: [],
      startMonthYear: dayjs().format('YYYY-MM'),
      today: dayjs().startOf('day'),
      show: false,
      mode: 'startSelect',
      endDatesInfo: [],
      containerWidth: 0,
      query: this.$route.query,
      targetId: '',
      showAtlantisDeals: false,
      atlantisDealDay: [],
    };
  },
  created() {
    dayjs.locale(this.lang);
    this.getWeekName();
    window.addEventListener('resize', this.refreshContainerSize);
  },
  mounted() {
    this.refreshContainerSize();

    setTimeout(() => {
      this.selectionDate1 = this.query.checkIn
        ? dayjs(this.query.checkIn)
        : null;
      this.selectionDate2 = this.query.checkOut
        ? dayjs(this.query.checkOut)
        : null;
    }, 500);
  },
  destroyed() {
    window.removeEventListener('resize', this.refreshContainerSize);
  },
  computed: {
    destinations() {
      return this.destination.split(',');
    },
    thisMonth() {
      return dayjs().month();
    },
    thisYear() {
      return dayjs().year();
    },
    monthYears() {
      const monthYears = [];
      for (let i = 0; i < 1; i += 1) {
        const monthYear = dayjs(`${this.startMonthYear}-01`).add(i, 'months');
        monthYears.push(monthYear);
      }
      return monthYears;
    },
    inputText() {
      let text = '';
      if (this.selectionDate1) {
        text += `${this.selectionDate1.format('DD/MM/YY')} - `;
      }
      if (this.selectionDate2) {
        text += this.selectionDate2.format('DD/MM/YY');
      }
      return text;
    },
    noOfMonthsInRow() {
      let slots = Math.floor(this.containerWidth / 240);
      if (slots > 2) {
        slots = 2;
      }
      return slots;
    },
    selectionDate1: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.from
          ? this.$store.getters.GET_SEARCH_CONTENT.from
          : '';
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['from', value]);
      },
    },
    selectionDate2: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.to
          ? this.$store.getters.GET_SEARCH_CONTENT.to
          : '';
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['to', value]);
      },
    },
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      holidayList: 'GET_HOLIDAY_DATA',
      device: 'GET_DEVICE',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      isFCLAgent: 'IS_FCL_ODYSSEY_AGENT_CODE',
      currentPage: 'GET_CURRENT_PAGE',
      isEmalonMode: 'GET_IS_EMALON_MODE',
      dealsAtlantis: 'GET_DEALS_ATLANTIS',
    }),
    enableSearchAgentState() {
      const { isOdysseySite, currentPage, isEmalonMode } = this;
      return ((isOdysseySite && currentPage !== 'search-result') || isEmalonMode);
    },
    isMobile() {
      return this.device === 'mobile';
    },
    dealsHtml() {
      let html = '';
      this.atlantisDealDay.forEach((deal) => {
        const night = this.calcNight(deal.checkoutDate, deal.checkinDate);
        html += `<div class="pt-1">
                  <div class="display-flex">
                      <div class="date-range" >
                        <span dir="ltr">${this.changeDateFormat(deal.checkinDate)} - ${this.changeDateFormat(deal.checkoutDate)}</span>
                      </div>
                      <div class="night-box" >
                        <span> ${night} ${(night > 1) ? this.$t('home.nights') : this.$t('home.night')} </span>
                      </div>
                      <div class="name-box">
                        <span> ${deal.hotelName} </span>
                      </div>
                      <div class="price-box">
                        <span class="currency-symbol"> ₪</span><strong>${Math.round(deal.totalAfterDiscount)}</strong>
                      </div>
                    </div>
                </div>`;
      });
      return html;
    },
    disableDealTooltip() {
      return this.atlantisDealDay.length === 0;
    },
  },
  watch: {
    selectionDate1() {
      this.emitDates();
    },
    selectionDate2() {
      this.emitDates();
    },
    lang() {
      dayjs.locale(this.lang);
      this.getWeekName();
      this.nextMonth();
      this.previousMonth();
    },
    calendarShow() {
      this.clearDatesSelection();
      this.show = this.calendarShow;
    },
  },
  methods: {
    setStartDate() {
      if (this.selectionDate1) {
        this.startMonthYear = dayjs(this.selectionDate1).format(
          'YYYY-MM',
        );
      } else {
        this.startMonthYear = dayjs().format('YYYY-MM');
      }
    },
    getWeekName() {
      this.weekDays = [
        this.$t('weekShortName.sun'),
        this.$t('weekShortName.mon'),
        this.$t('weekShortName.tue'),
        this.$t('weekShortName.wed'),
        this.$t('weekShortName.thu'),
        this.$t('weekShortName.fri'),
        this.$t('weekShortName.sat'),
      ];
    },
    emitDates() {
      if (this.selectionDate1) {
        this.startMonthYear = dayjs(this.selectionDate1).format(
          'YYYY-MM',
        );
      }
      if (
        this.selectionDate1
        && this.selectionDate1.get('date')
          === this.selectionDate1.daysInMonth()
      ) { this.nextMonth(); }
      this.$emit('dates', {
        from: this.selectionDate1
          ? this.selectionDate1.format('YYYY-MM-DD')
          : '',
        to: this.selectionDate2
          ? this.selectionDate2.format('YYYY-MM-DD')
          : '',
      });
    },
    weekWiseDays(monthYear) {
      const days = [];
      let day = monthYear.clone();
      while (day.format('YYYY-MM') === monthYear.format('YYYY-MM')) {
        days.push(day);
        day = day.add(1, 'days');
      }

      const weekWiseDays = [];
      let i = 0;
      days.forEach((dday) => {
        if (!weekWiseDays[i]) {
          weekWiseDays[i] = [];
        }
        const position = dday.day();
        if (position != null) {
          weekWiseDays[i][position] = dday;
          if (position === this.weekDays.length - 1) {
            i += 1;
          }
        }
      });
      return weekWiseDays;
    },
    displayMonthYear(monthYear) {
      return `${monthYear.format('MMMM')} ${monthYear.format('YYYY')}`;
    },
    toggleCalendar() {
      this.show = !this.show;
      if (this.show) {
        this.mode = 'startSelect';
      }
      this.refreshContainerSize();
      this.setStartDate();
    },
    nextMonth() {
      this.startMonthYear = dayjs(`${this.startMonthYear}-01`)
        .add(1, 'months')
        .format('YYYY-MM');
    },
    previousMonth() {
      this.startMonthYear = dayjs(`${this.startMonthYear}-01`)
        .subtract(1, 'months')
        .format('YYYY-MM');
    },
    isAvailable(day) {
      if (!day) {
        return false;
      }
      if (!day.isSameOrAfter(this.today)) {
        return false;
      }
      return true;
    },
    showTooltip(day) {
      return this.isOdysseySite && this.device !== 'mobile' && this.isAvailable(day);
    },
    hasAtlantisDeal(day) {
      let hasDeal = false;
      if (!day) return hasDeal;
      if (!day.isSameOrAfter(this.today)) return hasDeal;

      for (let i = 0; i < this.destinations.length; i += 1) {
        const dest = this.destinations[i];
        if (dest !== '' && this.dealsAtlantis[dest]?.length > 0) {
          hasDeal = true;
        }
        const dayStr = day.format('YYYY-MM-DD');
        // console.log(this.dealsAtlantis, dayStr);
        hasDeal = this.dealsAtlantis?.[dest]?.some((deal) => deal.checkinDate.includes(dayStr)) ?? false;

        if (hasDeal) break;
      }

      // hasDeal = true;
      return hasDeal;
    },
    enterDate(day) {
      if (this.mode === 'endSelect') {
        this.tempSelectionDate2 = day;
      }
    },
    changeDateFormat(str) {
      return str.split('T')[0].split('-').reverse().join('.');
    },
    calcNight(day1, day2) {
      const a = dayjs(this.changeDateFormat(day1), 'DD/MM/YY');
      const b = dayjs(this.changeDateFormat(day2), 'DD/MM/YY');
      return a.diff(b, 'day');
    },
    hoverDate(day, tooltipID) {
      this.atlantisDealDay = [];
      this.destinations.forEach((dest) => {
        if (this.dealsAtlantis[dest]?.length > 0) {
          const strDate = day?.format('YYYY-MM-DD') ?? '';
          const deals = this.dealsAtlantis[dest].filter((deal) => dayjs(deal.checkinDate).format('YYYY-MM-DD') === strDate);
          this.atlantisDealDay = deals.reduce((uniqueDeals, currDeal) => {
            if (!uniqueDeals.some((deal) => deal.checkinDate === currDeal.checkinDate && deal.checkoutDate === currDeal.checkoutDate && deal.totalPrice === currDeal.totalPrice && deal.hotelCode === currDeal.hotelCode)) {
              uniqueDeals.push(currDeal);
            }
            return uniqueDeals;
          }, this.atlantisDealDay);
          if (this.atlantisDealDay.length > 0 && !this.isMobile) {
            this.showAtlantisDeals = true;
            // this.$root.$emit('bv::hide::tooltip');
            this.$root.$emit('bv::show::tooltip', tooltipID);
          }
        }
      });
    },
    selectDate(day) {
      if (this.mode === 'startSelect') {
        this.selectionDate1 = day;
        this.selectionDate2 = null;
        this.mode = 'endSelect';
      } else {
        if (day.isSameOrBefore(this.selectionDate1)) return;
        this.selectionDate2 = day;
        this.tempSelectionDate2 = null;
        this.mode = 'startSelect';
      }
    },
    isStartDate(day) {
      if (!day) {
        return false;
      }
      return (
        this.selectionDate1
        && this.selectionDate1.format('YYYY-MM-DD') === day.format('YYYY-MM-DD')
      );
    },
    isEndDate(day) {
      if (!day) {
        return false;
      }
      return (
        this.selectionDate2
        && this.selectionDate2.format('YYYY-MM-DD') === day.format('YYYY-MM-DD')
      );
    },
    isHoliday(day) {
      if (!day) {
        return '';
      }
      const formattedDay = day.format('YYYY-MM-DD');
      return this.holidayList?.find(
        (item) => item.active && item.date === formattedDay,
      )?.hebrew;
    },
    isToday(day) {
      if (!day) {
        return false;
      }
      return this.today.format('YYYY-MM-DD') === day.format('YYYY-MM-DD');
    },
    clearDatesSelection() {
      this.selectionDate1 = null;
      this.selectionDate2 = null;
      this.mode = 'startSelect';
      this.$emit('finalDateSelect', false);
    },
    finalizeSelection() {
      this.show = false;
      this.$emit('finalDateSelect', true);
    },
    isUnderSelection(day) {
      if (!day) {
        return false;
      }
      if (this.mode === 'endSelect') {
        return (
          this.selectionDate1
          && this.tempSelectionDate2
          && day.isSameOrAfter(this.selectionDate1)
          && day.isSameOrBefore(this.tempSelectionDate2)
        );
      } else {
        return (
          this.selectionDate1
          && this.selectionDate2
          && day.isSameOrAfter(this.selectionDate1)
          && day.isSameOrBefore(this.selectionDate2)
        );
      }
    },
    refreshContainerSize() {
      const width = this.$refs.inputControl.clientWidth;
      this.containerWidth = width < 250 ? 250 : width;
    },
  },
};
